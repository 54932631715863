
import React from "react";
import Navbar from "./Navbar";

const Subscription = () => {
  const checkout = async () => {
    try {
      const response = await fetch(
        "https://api.thoushallnot.com/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        const json = await response.json();
        return Promise.reject(json);
      }

      const json = await response.json();
      window.location.href = json.url; // Redirect to Stripe checkout
    } catch (e) {
      console.log(e.error);
    }
  };

  return (
    <div className="container mx-auto">
      <Navbar />
      <div className="cards-container">
        <div className="card">
          <h4>No Refund</h4>
          <p>
            Our subscription plan comes with a no refund policy. Please consider
            before making a purchase.
          </p>
        </div>

        <div className="card">
          <h4>Stripe Payment</h4>
          <p>
            We use Stripe for secure and reliable payment processing. Your
            transactions are safe and encrypted.
          </p>
        </div>

        <div className="card">
          <h4>Unlimited Comments</h4>
          <p>
            Enjoy the benefits of unlimited comments with our yearly
            subscription plan. Engage freely!
          </p>
        </div>
      </div>
      <div className="subs-text">
        <h3>FOR UNLIMITED COMMENTS !!! PAY A YEARLY SUBSCRIPTION NOWW !!</h3>
        <h>
          <strong>PAY NOW !!!</strong>
        </h>
        <button className="subs-btn sub-trans" onClick={checkout}>
          STRIPE TRANSFERR
        </button>
      </div>
    </div>
  );
};

export default Subscription;
