import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MyContext } from "./context";

const PostDetails = () => {
  const [postDetails, setPostDetails] = useState(null);
  const { postId } = useParams();
  const { state } = useContext(MyContext);

  const fetchPostDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.thoushallnot.com/get-post/${postId}`
      );
      console.log(response.data.data.post);
      setPostDetails(response.data.data.post);
    } catch (error) {
      console.error("Error fetching post details:", error.message);
    }
  };

  useEffect(() => {
    fetchPostDetails();
  }, [postId]);

  useEffect(()=>{
    if(state=="light"){
      document.body.style.backgroundColor="#fff";
    }
    else{
      document.body.style.backgroundColor="#151b21";
    }
  },[state])

  if (!postDetails) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="post-details post" style={{backgroundColor:state=="light"?"white":"#1e262f"}}>
        <div className="post-userinfo">
          <p>Random ID: {postDetails.randomId}</p>
          <p>Timing: {new Date(postDetails.timing).toLocaleString()}</p>
        </div>
        <div className="post-link">
          <h2 style={{color:state=="light"?"black":"white"}}>{postDetails.comment}</h2>
        </div>
        <div className="post-stats">
          <p style={{color:state=="light"?"black":"#96a4ab"}}>Likes: {postDetails.likes.length}</p>
        </div>
      </div>

      {postDetails.replies.map((val, key) => (
        <div key={key} className="post" style={{backgroundColor:state=="light"?"white":"#1e262f"}}>
            <div className="post-userinfo">
              <p style={{ marginTop: "3px", marginLeft: "12px" }}>
                <span>#{val.userId}</span>
              </p>
              <p style={{ marginTop: "3px" }}>{new Date(val.timing).toLocaleString()}</p>
            </div>
            <p style={{ color: "black", marginLeft: "12px", color:state=="light"?"black":"#96a4ab" }}>
              {val.replyText}
            </p>
        </div>
      ))}
    </>
  );
};

export default PostDetails;
