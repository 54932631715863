import React,{useEffect, useState, useContext} from "react";
import PostComment from "./PostComment";
import Post from "./Post";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import Cookies from 'js-cookie';
import {Link} from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MyContext } from "./context";
import axios from "axios";

const Main = () => {
  const [basicModal, setBasicModal] = useState(false);
  const { state } = useContext(MyContext);
  const [allcomments,setAllcomments]=useState([]);

  const toggleOpen = () => setBasicModal(!basicModal);
  useEffect(()=>{
    fetchComments();
    if(!Cookies.get("accept")){
      toggleOpen();
    }
  },[])

  const fetchComments = async () => {
    try {
      const response = await axios.get("https://api.thoushallnot.com/get-comments");
      setAllcomments(response.data.data.comments);
    } catch (error) {
      console.error("Error fetching comments:", error.message);
    }
  };

  return (
    <>
      <PostComment />
      <Post />
      <center>
        <div style={{fontSize:"12px",marginTop:allcomments.length<2?"6%":"10px",marginBottom:"10px"}}>
          <Link to="/privacy" style={{ color: "grey", cursor: "pointer", margin: "0 4px" }}>
            Privacy Policy |
          </Link>
          <Link to="/terms-of-services" style={{ color: "grey", cursor: "pointer", margin: "0 4px" }}>
            Terms of Services |
          </Link>
          <Link to="/boosting-policy" style={{ color: "grey", cursor: "pointer", margin: "0 4px" }}>
            Boosting Policy
          </Link>
        </div>
      </center>
      <center>
        <MDBModal open={basicModal}>
        <MDBModalDialog scrollable>
          <MDBModalContent style={{backgroundColor:state=="light"?"white":"#1e262f"}}>
            <MDBModalHeader>
              <MDBModalTitle style={{color:state=="light"?"black":"white"}}>Terms Of Services</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
            <h5 style={{fontWeight:"bold",overflowY:"hidden",color:state=="light"?"black":"white"}}>Terms & Conditions</h5>
            <center><span style={{color:state=="light"?"black":"white"}}>Last Updated: 1/21/2024</span></center>
            <div style={{textAlign:"left",margin:"30px",overflowY:"hidden",color:state=="light"?"black":"white"}}>
                <p>Welcome to Thoushallnot.com! Please read these terms and conditions ("Terms") carefully before using our website. By accessing or using our website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website.</p>
                <h5 style={{overflowY:"hidden"}}>1. General Information: </h5>
                <p>ThoushallNot (hereinafter referred to as "the Website") is an anonymous comment/confessions website that allows users to post content anonymously. Users can engage and comment on posts without the need for any sign-up or registration. The following subclauses provide additional details:</p>
                <h5 style={{overflowY:"hidden"}}>2.	User Content: </h5>
                <p>The Website enables users to submit text-based content, including comments and confessions, without the requirement of revealing their identity or personal information.</p>
                <ul>
                    <li><b>• Anonymous Nature:</b> Users have the option to post content without any personal attribution, ensuring that their identity remains concealed from other users.</li>
                    <li><b>• Community Interaction: </b> The Website fosters a sense of community by allowing users to engage in discussions and provide feedback on each other's posts anonymously.</li>
                    <li><b>• No User Registration:  </b> Unlike some platforms, the Website does not mandate user registration, ensuring that users can freely participate without the burden of creating accounts.</li>
                    <li><b>• Content Variety:  </b> Users can contribute a wide range of content, including but not limited to confessions, opinions, stories, and comments, as long as it adheres to the Terms and Conditions outlined herein.</li>
                </ul>
                <h5 style={{overflowY:"hidden"}}>3.	User Responsibility:</h5>
                <p> Users are solely responsible for the content they post on the Website. The following subclauses provide more specific guidelines:</p>
                <ul>
                    <li><b>• Content Standards:</b> Users must adhere to high content standards and may not post material that is threatening, abusive, or harassing in nature. This includes refraining from using hate speech, discriminatory language, or offensive material.</li>
                    <li><b>• Privacy and Consent:</b> Users must respect the privacy and consent of individuals. Posting personal information, such as addresses, phone numbers, or other private details, without the explicit consent of the individual involved is strictly prohibited.</li>
                    <li><b>• Defamatory Content:</b> Users may not post defamatory content that falsely harms the reputation of individuals or entities. Any content that constitutes defamation will be subject to removal.</li>
                    <li><b>• Illegal Activities:</b> Users must not engage in any illegal activities through their posts or comments. This includes, but is not limited to, incitement to violence, distribution of illegal materials, or participation in criminal activities.</li>
                    <li><b>• Compliance with Laws:</b> Users must not engage in any illegal activities through their posts or comments. This includes, but is not limited to, incitement to violence, distribution of illegal materials, or participation in criminal activities.</li>
                    <li><b>• Illegal Activities:</b> Users must ensure that their content complies with all applicable local, national, and international laws and regulations. Violating any laws or regulations through posted content will not be tolerated.</li>
                </ul>

                <h5 style={{overflowY:"hidden"}}>4.	Intellectual Property:</h5>
                <p>Users retain ownership of the content they post on the Website. The following subclauses provide more specific details about intellectual property rights:</p>
                <ul>
                    <li><b>• User Ownership: </b> Users maintain full ownership of the content they create and post on the Website. This includes comments, confessions, or any other content contributed.</li>
                    <li><b>• Content License: </b> By posting content on the Website, users grant the Website a non-exclusive, worldwide, royalty-free license. This license allows the Website to use, reproduce, and distribute the content for the sole purposes of operating, maintaining, and promoting the Website.</li>
                    <li><b>• Limited Use: </b> The granted license is limited to the operation and promotion of the Website itself and does not extend to third-party use. The Website will not sell or license user-generated content to third parties for commercial purposes.</li>
                    <li><b>• Attribution: </b> The Website will not claim ownership of user-generated content, and appropriate attribution will be given to the respective content creators when used for promotional purposes.</li>
                    <li><b>• No Unauthorized Reproduction: </b> Users may not use or reproduce any content from the Website without obtaining explicit permission from the respective content creators. This applies to both personal and commercial use of the content.</li>
                </ul>
                <h5 style={{overflowY:"hidden"}}>5.	Disclaimer of Liability:</h5>
                <p>The Website is provided on an "as-is" and "as available" basis. We do not guarantee the accuracy, completeness, or availability of any content on the Website. The Website is not responsible for any harm, loss, or damage resulting from the use of the Website. The following are 10 specific circumstances in which the Website will not be liable:</p>
                <ul>
                    <li><b>• Third-Party Content:  </b> The Website does not control or endorse the content posted by users or third parties. We are not liable for the accuracy, legality, or quality of such content.</li>
                    <li><b>• Technical Issues:  </b> The Website will not be liable for any interruptions, delays, or technical malfunctions that may occur while using the Website, including server downtime or network issues.</li>
                    <li><b>• User Misconduct:  </b> We are not responsible for any harm or damage resulting from the misconduct or inappropriate behavior of other users, including offensive comments or harassment.</li>
                    <li><b>• Unauthorized Access: </b> The Website is not liable for any unauthorized access to user accounts, hacking attempts, or data breaches, as we employ reasonable security measures to protect user data.</li>
                    <li><b>• Loss of Data:  </b> We are not responsible for any loss of user-generated content or data, including comments, confessions, or any other information posted on the Website.</li>
                    <li><b>• Reliance on Content:</b> Users should not rely solely on the information and content found on the Website for important decisions. We do not guarantee the accuracy or suitability of content for specific purposes.</li>
                    <li><b>• External Links:</b>  The Website may contain links to external websites or resources. We are not responsible for the content, safety, or practices of these third-party sites.</li>
                    <li><b>• Force Majeure:</b> The Website will not be liable for any failure to perform its obligations due to circumstances beyond its control, such as natural disasters, acts of war, or government regulations.</li>
                    <li><b>• User Actions: </b>Users are responsible for their actions and interactions on the Website. The Website will not be liable for any disputes, conflicts, or agreements between users.</li>
                    <li><b>• Legal Compliance:  </b>  The Website is not responsible for ensuring that users' posts or comments comply with applicable laws and regulations. Users are expected to adhere to legal requirements when using the Website.</li>
                </ul>
                <h5 style={{overflowY:"hidden"}}>6.	Governing Law: </h5>
                <p>These Terms are governed by and construed in accordance with the laws of the State of Ohio, United States of America. Any disputes arising from or related to these Terms, your use of the Website, or any interactions with the Website shall be subject to the exclusive jurisdiction of the state and federal courts located within the State of Ohio. You hereby consent to the personal jurisdiction of such courts and waive any objections to the exercise of jurisdiction by such courts. If you are accessing or using the Website from outside the United States, you are responsible for compliance with all local laws.</p>
                <h5 style={{overflowY:"hidden"}}>7.	Contact Information:</h5>
                <p>For any questions or concerns regarding these Terms or the Website, please contact us at <a href="mailto:thoushallnotconfessions@outlook.com">thoushallnotconfessions@outlook.com</a>.</p>
                <h5 style={{overflowY:"hidden"}}>8. Privacy Policy</h5>
                <p>Our Privacy Policy, which outlines how we collect, use, and protect user data, is an integral part of these Terms and can be accessed separately on our website.</p>
                <h5 style={{overflowY:"hidden"}}>9.	Third-Party Links</h5>
                <p>The Website may contain links to third-party websites or content. We are not responsible for the content or practices of these third-party sites and recommend reviewing their terms and privacy policies.</p>
                <h5 style={{overflowY:"hidden"}}>10. Indemnification</h5>
                <p>Users agree to indemnify and hold harmless the Website, its owners, affiliates, employees, and agents (collectively referred to as "the Indemnified Parties") from any and all claims, damages, liabilities, losses, costs, and expenses, including reasonable attorney's fees, arising from:</p>
                <ul>
                    <li>• Their use of the Website or any services provided through the Website.</li>
                    <li>• Any content they submit, post, or transmit on or through the Website.</li>
                    <li>• Any violation of these Terms of Service or any applicable laws or regulations by the user.</li>
                    <li>• Users agree to cooperate fully in the defense of any claim and to promptly provide the Indemnified Parties with any information or assistance necessary for the defense of such claims. The Indemnified Parties reserve the right to assume the exclusive defense and control of any matter subject to indemnification by users, and users shall not settle any claim without the prior written consent of the Indemnified Parties.</li>
                </ul>
                <h5 style={{overflowY:"hidden"}}>11. Termination:</h5>
                <p>The Website reserves the right to, without prior notice, terminate or suspend a user's access to the Website or its services for any reason deemed necessary, including but not limited to:</p>
                <ul>
                    <li>• Repeated violations of these Terms of Service.</li>
                    <li>• Violations of any applicable laws or regulations.</li>
                    <li>• Actions that may harm the Website, its users, or its reputation.</li>
                    <li>• Suspected fraudulent or illegal activities associated with the user's account.</li>
                    <li>• Technical or security issues that pose a risk to the Website or its users.</li>
                    <li>• Non-payment of any applicable fees or charges.</li>
                    <li>• In the event of termination or suspension, users may lose access to their accounts, content, and any associated data. The Website shall not be liable to users or any third party for any such termination or suspension of access.</li>
                    <li>• Users who wish to appeal a termination or suspension may contact the Website's support team at contact email o request a review of their case. However, the Website retains the sole discretion to make final determinations regarding access reinstatement.</li>
                </ul>
                <h5 style={{overflowY:"hidden"}}>12. Limitation of Liability: </h5>
                <p>To the fullest extent permitted by law, the Website, its owners, and affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of or inability to use the Website.</p>
                <h5 style={{overflowY:"hidden"}}>13. User Feedback: </h5>
                <p>Users are encouraged to provide feedback, comments, and suggestions to improve the Website. By providing feedback, users grant the Website the right to use and implement their suggestions without any obligation.</p>
                <h5 style={{overflowY:"hidden"}}>14. Assignment: </h5>
                <p>These Terms are binding upon and inure to the benefit of the parties and their respective successors and assigns.</p>
                <br />
                <p>By using the Website, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you do not agree with any part of these Terms, please refrain from using the Website.</p>
                <p style={{fontWeight:"bold"}}>Thank you for using Thoushallnot!</p>
                <br />
                <h3 style={{textDecoration:"underline",overflowY:"hidden"}}>Community Guidelines</h3>
                <p>Welcome to Thoushallnot.com. To ensure a positive and enriching experience for all, we've developed these comprehensive community guidelines. Please read and understand these guidelines thoroughly, as they outline the principles and expectations that govern our platform.</p>
                <h5 style={{overflowY:"hidden"}}>1.	Respectful Conduct:</h5>
                <p>At the heart of our community is a commitment to respectful and considerate interactions. Upholding this principle is crucial in fostering a welcoming and supportive environment for everyone.</p>
                <h5 style={{overflowY:"hidden"}}>2.	Treat Others with Respect:</h5>
                <ul>
                    <li>• Our community thrives on mutual respect. Treat fellow community members, moderators, and administrators with kindness and consideration in all interactions.</li>
                    <li>• Avoid personal attacks, harassment, or bullying in any form. We expect all users to engage in discussions with civility and empathy.</li>
                </ul>
                <h5 style={{overflowY:"hidden"}}>3.	No Hate Speech or Discrimination:</h5>
                <p>Hate speech, discrimination, or any form of comment that promotes violence or harm against individuals or groups based on attributes such as race, ethnicity, gender, sexual orientation, religion, or disability will not be tolerated. Embrace diversity and inclusion, and refrain from engaging in any behavior that marginalizes or harms others.</p>
                <h5 style={{overflowY:"hidden"}}>4.	User Privacy and Safety: </h5>
                <p>Ensuring the safety and privacy of our community members is a top priority. We trust that you will join us in upholding these principles.</p>
                <h5 style={{overflowY:"hidden"}}>5.	Protect Personal Information:</h5>
                <p>We strongly advise against sharing personal information, including contact details, addresses, or financial information, within the community. Safeguard your privacy and respect the privacy of others. In the interest of maintaining a secure online environment, exercise caution when divulging personal information even in private conversations.</p>
                <h5 style={{overflowY:"hidden"}}>6.	Reporting Safety Concerns: </h5>
                <p>To preserve a safe online environment for all, we encourage users to promptly report safety concerns, threats, or any suspicious behavior they encounter on the platform to our moderators or administrators. Your vigilance and cooperation contribute significantly to maintaining the community's well-being.</p>
                <h5 style={{overflowY:"hidden"}}>7.	Comment Guidelines:  </h5>
                <p>Contributing to our community is a privilege that comes with certain responsibilities. We expect all users to adhere to these comment guidelines.</p>
                <h5 style={{overflowY:"hidden"}}>8.	Share Original Comments:</h5>
                <p> When posting comments within our community, ensure that they are original or that you possess the necessary rights and permissions to distribute them. Respecting copyright and intellectual property rights is paramount. Plagiarism or unauthorized use of copyrighted materials is strictly prohibited.</p>
                <h5 style={{overflowY:"hidden"}}>9.	Avoid Harmful Comments: </h5>
                <p>We maintain a standard of appropriateness for our community. Users must refrain from posting harmful, explicit, adult-oriented, or offensive comments that may be inappropriate for a general audience. Exercise good judgment when sharing comments, considering their impact on others.</p>
                <h5 style={{overflowY:"hidden"}}>10. Provide Accurate and Truthful Information:  </h5>
                <p>Contributing accurate and truthful information is essential to the integrity of our community. When sharing information or opinions, do so honestly and transparently to the best of your knowledge. 1When applicable, cite sources to support your claims. Transparency enhances the quality of our discussions.</p>
                <h5 style={{overflowY:"hidden"}}>11. Constructive Discussions:  </h5>
                <p>Engaging in meaningful conversations and contributing positively to our community discussions are encouraged behaviors.</p>
                <h5 style={{overflowY:"hidden"}}>12. Keep Discussions Relevant: </h5>
                <p>Maintain the relevance of your comments to the platform's designated topics and purpose. Straying too far from the intended focus can disrupt the flow of discussions and detract from the community's value. By adhering to topic relevance, you help create a more organized and informative community space.</p>
                <h5 style={{overflowY:"hidden"}}>13. Encourage Constructive Criticism: </h5>
                <p>Constructive criticism and thoughtful dialogue enrich our community. Encourage open and respectful discussions that facilitate growth, learning, and improvement. Engage in meaningful exchanges that contribute positively to the collective knowledge of our community.</p>
                <h5 style={{overflowY:"hidden"}}>14. Avoid Disruptive Behaviors:  </h5>
                <p>Foster a respectful environment by avoiding disruptive behaviors such as spamming, trolling, or any activities intended to derail or disrupt conversations. Engage in discussions with an aim to learn, share, and connect with others in a productive manner.</p>
                <h5 style={{overflowY:"hidden"}}>15. Reporting Violations: </h5>
                <p>Maintaining a safe and respectful community requires cooperation from all members. Reporting guideline violations is a fundamental way to uphold the standards we've set.</p>
                <h5 style={{overflowY:"hidden"}}>16. Use the Reporting System:  </h5>
                <p>To address guideline violations or breaches of our Terms of Service, utilize the platform's designated reporting system. It is your tool to bring attention to issues that require moderation or intervention. When reporting, provide specific details and context to assist moderators or administrators in their assessment.</p>
                <h5 style={{overflowY:"hidden"}}>17. Confidentiality in Reporting: </h5>
                <p>Maintain the confidentiality of reports made by fellow community members. It is crucial to respect their privacy and protect their identities. 1Public discussion or sharing of private information related to reports is strictly prohibited.</p>
                <h5 style={{overflowY:"hidden"}}>18. Enforcement and Consequences: </h5>
                <p>Ensuring that our guidelines are followed is essential for a thriving community. Here, we detail the processes for enforcement and consequences.</p>
                <h5 style={{overflowY:"hidden"}}>19. Violations and Consequences:  </h5>
                <p>Violations of these community guidelines may result in a range of consequences, including verbal warnings, temporary suspensions, or permanent bans. The severity of consequences will depend on the nature and recurrence of infractions. These actions are taken by our moderators and administrators to preserve the quality of our community and protect the well-being of its members.</p>
                <h5 style={{overflowY:"hidden"}}>20. Appeal Process:  </h5>
                <p>Users have the right to appeal actions taken against their accounts due to guideline violations. The appeal process is outlined in the Terms of Service and provides a mechanism for users to present their case. We encourage users to follow the designated appeal process if they believe an action taken against their account requires reconsideration.</p>
                <h5 style={{overflowY:"hidden"}}>21. Administrator Discretion:  </h5>
                <p>The administrators and moderators of the platform reserve the discretion to enforce these guidelines as they see fit, always with the best interests of the community in mind. They aim to create a fair and balanced online environment where all members can engage and contribute positively.</p>
            </div>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn style={{backgroundColor:"#df0000",color:"white",border:"none"}} onClick={()=>{
                Cookies.set("accept","ok");
                setBasicModal(false);
              }}>Accept</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </center>
    </>
  );
};

export default Main;
