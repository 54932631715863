import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Components/Main";
import Subscription from "./Components/Subscription";
import PostDetails from "./Components/PostDetails"; // Import PostDetails component
import Login from "./Components/Login";
import Register from "./Components/Register";
import Success from "./Components/Success";
import PostComment from "./Components/PostComment";
import Boosting from "./Components/BosstingPolicy";
import Privacy from "./Components/privacy";
import Post from "./Components/Post";
import Tos from "./Components/tos";
import Favourite from "./Components/favorite";
import Share from "./Components/share";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/register" element={<Register />} />
        <Route path="/success" element={<Success />} />
        <Route path="/new-post" element={<PostComment />} />
        <Route path="/all-post" element={<Post />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/terms-of-services" element={<Tos />} />
        <Route path="/boosting-policy" element={<Boosting />} />
        <Route path="/post/:postId" element={<PostDetails />} />{" "}
        <Route path="/favorite" element={<Favourite />} />{" "}
        <Route path="/share/:id" element={<Share />} />{" "}
      </Routes>
    </Router>
  );
}

export default App;
