import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import dark from "../assets/dark.png";
import { MDBRow,MDBCol } from "mdb-react-ui-kit";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import toast,{Toaster} from 'react-hot-toast';
import Navbar from "./Navbar";
import { MyContext } from "./context";

const Favourite = () => {
  const [allcomment, setAllcomments] = useState([]);
  const [backup,setBackup]=useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [replayId,setReplayId]=useState("");
  const [comment, setComment] = useState("");
  const [basicModal, setBasicModal] = useState(false);
  const [isfilter,setIsfilter]=useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);
  const { state } = useContext(MyContext);

  const categories = [
    "Choose Category",
    "Personal Growth",
    "Relationships",
    "Workplace",
    "School/College",
    "Social Issues",
    "Regrets",
    "Secrets",
    "Achievements",
    "Fears",
    "Dreams and Aspirations",
    "Feedback",
    "Question",
    "Other"
  ];

  const fetchComments = async () => {
    try {
      const response = await axios.get("https://api.thoushallnot.com/get-comments");
      setAllcomments(response.data.data.comments);
      setBackup(response.data.data.comments);
    } catch (error) {
      console.error("Error fetching comments:", error.message);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  useEffect(() => {
    if (isfilter) {
      fetchComments();
      const intervalId = setInterval(fetchComments, 2000);
      return () => clearInterval(intervalId);
    }
  }, [!isfilter]);

  useEffect(()=>{
    if(state=="light"){
      document.body.style.backgroundColor="#fff";
    }
    else{
      document.body.style.backgroundColor="#151b21";
    }
  },[state])

  const handleBoost = async (commentId) => {
    try {
      const response = await axios.post(
        `https://api.thoushallnot.com/create-checkout-session?commentId=${commentId}`
      );
      if(response){
        window.location.href=response.data.url;
      }
    } catch (error) {
      console.error("Error handling request:", error.message);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if(e.target.value==""){
      setAllcomments(backup);
    }
    else{
      const filter = backup.filter((item) => 
        item.comment && item.comment.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setAllcomments(filter);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("https://api.thoushallnot.com/post-reply", {
        replayId,
        comment,
        userId: localStorage.getItem("userId"),
      });

      if (response.status === 200) {
        toast.success("Reply sent successfully!");
        fetchComments();
        toggleOpen();
        setComment(""); 
      }
    } catch (error) {
      console.error("Error sending reply:", error);
      toast.error(
        error.response?.data?.message || "An error occurred while posting the reply."
      );
    }
  };

  const handleFavorite = async (commentId) => {
    try {
      const response = await axios.post("https://api.thoushallnot.com/favorite", {
        commentId,
        userId: localStorage.getItem("userId"),
      });

      if (response.status === 200) {
        fetchComments();
      }
    } catch (error) {
      console.error("Error sending reply:", error);
      toast.error(
        error.response?.data?.message || "Error occur"
      );
    }
  };

  const handleUnFavorite = async (commentId) => {
    try {
      const response = await axios.post("https://api.thoushallnot.com/unfavorite", {
        commentId,
        userId: localStorage.getItem("userId"),
      });

      if (response.status === 200) {
        fetchComments();
      }
    } catch (error) {
      console.error("Error sending reply:", error);
      toast.error(
        error.response?.data?.message || "Error occur"
      );
    }
  };

  const handleLike = async (commentId) => {
    try {
      const response = await axios.post("https://api.thoushallnot.com/like", {
        commentId,
        userId: localStorage.getItem("userId"),
      });

      if (response.status === 200) {
        fetchComments();
      }
    } catch (error) {
      console.error("Error sending reply:", error);
      toast.error(
        error.response?.data?.message || "Error occur"
      );
    }
  };

  const handleUnLike = async (commentId) => {
    try {
      const response = await axios.post("https://api.thoushallnot.com/unlike", {
        commentId,
        userId: localStorage.getItem("userId"),
      });

      if (response.status === 200) {
        fetchComments();
      }
    } catch (error) {
      console.error("Error sending reply:", error);
      toast.error(
        error.response?.data?.message || "Error occur"
      );
    }
  };

  const handleShare = (comment) => {
    if (navigator.share) {
      navigator.share({
        title: 'Thoushallnot Confession',
        text: `https://thoushallnot.com/share/${comment}`,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  return (
    <>
    <Navbar/>
      <main className="container mx-auto">
        <center><h3 style={{color:state=="light"?"black":"white"}}>Favorite Confessions</h3></center>
            {allcomment.map((val, key) => {
            return val.favorites.some(favorite => favorite.userId == localStorage.getItem('userId')) ? (
                <div key={key} className="post">
                    <Link key={key} to={`/post/${val._id}`} className="post-link">
                        <div className="post-userinfo">
                            <p style={{ marginTop: "3px",marginLeft:"12px" }}>
                            <span>#{val.category}</span>
                            {val.boostPost && (
                                <span>
                                <i className="fas fa-bolt" style={{ color: "blue", marginLeft: "10px" }}></i> Boosted
                                </span>
                            )}
                            </p>
                            <p style={{marginTop:"3px"}}>{new Date(val.timing).toLocaleString()}</p>
                        </div>
                        <p style={{color:"black",marginLeft:"12px", color:state=="light"?"#000":"#96a4ab"}}>
                            {val.comment}
                        </p>
                        </Link>
                        <div className="post-stats" style={{marginLeft:"12px",display:"flex",color:state=="light"?"black":"white"}}>
                        <i
                            className={`fa-regular fa-thumbs-up ${
                            val.likes.some(favorite => favorite.userId == localStorage.getItem('userId'))
                                ? "liked fa-solid fa-thumbs-up"
                                : ""
                            }`}
                            onClick={()=>{val.likes.some(favorite => favorite.userId == localStorage.getItem('userId'))?handleUnLike(val._id):handleLike(val._id)}}
                            style={{ cursor: "pointer",color:val.likes.some(favorite => favorite.userId == localStorage.getItem('userId'))?'blue': state=="light"?"black":"white" }}
                            ><span style={{fontSize:"12px",marginLeft:"5px"}}>{val.likes.length}</span></i>
                        <i className="fa fa-reply" style={{marginLeft:"25px",cursor:"pointer"}} onClick={()=>{setReplayId(val._id); console.log(val._id); toggleOpen()}}><span style={{fontSize:"12px",marginLeft:"5px"}}>{val.replies.length}</span></i>  
                        <i className="fa fa-heart" style={{marginLeft:"25px",cursor:"pointer",color:val.favorites.some(favorite => favorite.userId == localStorage.getItem('userId'))?'red': state=="light"?"black":"white"}} onClick={()=>{val.favorites.some(favorite => favorite.userId == localStorage.getItem('userId'))?handleUnFavorite(val._id):handleFavorite(val._id)}}></i> 
                        <i className="fa fa-share" style={{marginLeft:"25px",cursor:"pointer"}} onClick={()=>{handleShare(val._id)}}></i>
                        </div>
                        <MDBRow className="align-items-center justify-content-between">
                        <MDBCol md={val.boostPost? 10 : 8}></MDBCol>
                        <MDBCol md={val.boostPost? 2 : 2} className="d-flex justify-content-end">
                            <center><img src={state=="light"?logo:dark} style={{marginLeft:"30px",width:"100px"}}></img></center>
                        </MDBCol>
                        {!val.boostPost?(
                            <MDBCol md={2}>
                            <center>
                                <button className="clear-btn" style={{backgroundColor:"#df0000",border:"none"}} onClick={()=>{handleBoost(val._id)}}>
                                Boost
                                </button>
                            </center>
                            </MDBCol>
                        ):(
                            ""
                        )}
                    </MDBRow>
                </div>
            ) : null;
            })}
      </main>

      <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Reply</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                cols="30"
                rows="10"
                placeholder="Reply here.."
                style={{border:"1px solid #bdbdbd",borderRadius:"5px"}}
              ></textarea>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn style={{backgroundColor:"white",border:"1px solid #df0000",color:"#df0000"}} onClick={toggleOpen}>
                Close
              </MDBBtn>
              <MDBBtn style={{backgroundColor:"#df0000"}} onClick={handleSubmit}>Submit</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <Toaster />
    </>
  );
};

export default Favourite;
