import React,{useContext,useEffect} from "react";
import logo from "../assets/logo.png";
import dark from "../assets/dark.png";
import { MyContext } from "./context";

const Privacy = () => {
  const { state } = useContext(MyContext);
  
  useEffect(()=>{
      if(state=="light"){
      document.body.style.backgroundColor="#fff";
      }
      else{
      document.body.style.backgroundColor="#151b21";
      }
  },[state])

  return (
    <>
        <center>
            <img src={state=="light"?logo:dark} alt="ThoushallNot" style={{width:"350px"}}/>
            <h5 style={{fontWeight:"bold",overflowY:"hidden",color:state=="light"?"black":"white"}}>Privacy Policy</h5>
            <center><span style={{color:state=="light"?"black":"white"}}>Effective Date: 1/21/2024</span></center>
            <div style={{textAlign:"left",margin:"30px",overflowY:"hidden",color:state=="light"?"black":"white"}}>
                <h5 style={{overflowY:"hidden"}}>1. Introduction</h5>
                <p>This Privacy Policy applies to Thoushallnot.com ("we", "our", or "us") and governs our data collection, processing, and usage practices. It also describes your choices regarding use, access, and correction of your personal information. By using the website Thoushallnot.com, you consent to the data practices described in this policy.</p>
                <h5 style={{overflowY:"hidden"}}>2.	Scope of Policy</h5>
                <p>This policy applies to the information we collect through our website and in the context of our interactions with customers and visitors.</p>
                <h5 style={{overflowY:"hidden"}}>3.	Information Collection and Use</h5>
                <ul>
                  <li><b>•	Anonymous Users:</b> We collect and store comments made by anonymous users on our website. These comments are stored without attaching personal identifiers.</li>
                  <li><b>•	Subscribed Users:</b> For users who subscribe to our services, we collect names and email addresses.</li>
                </ul>
                <h5 style={{overflowY:"hidden"}}>4.	Use of Collected Information</h5>
                <p>•	The collected data is used solely for the purpose of providing and improving our services, website management, and communication with subscribed users.</p>
                <p>•	We will not use the data for any other reasons unless it becomes a matter of a legal issue where we are compelled to comply with law enforcement or legal directives.</p>
                <h5 style={{overflowY:"hidden"}}>5.	Legal Basis for Processing Personal Information</h5>
                <p>Our legal basis for collecting and using the personal information described in this policy depends on the personal data we collect and the specific context in which we collect it.</p>
                <h5 style={{overflowY:"hidden"}}>6.	Children’s Privacy (COPPA Compliance)</h5>
                <p>•	Consistent with the Children’s Online Privacy Protection Act (COPPA), we do not knowingly collect any personal information from children under the age of 13.</p>
                <p>•	In the event we learn we have collected personal information from a child under 13 without verification of parental consent, we will delete that information as quickly as possible.</p>
                <h5 style={{overflowY:"hidden"}}>7. Parental Control and Rights</h5>
                <p>Parents or guardians have the right to review the personal information collected from their children and may request its deletion or refuse further collection or use of the child's information.</p>

                <h5 style={{overflowY:"hidden"}}>8. Data Protection and Security</h5>
                <p>We take the protection of your data seriously and have implemented industry-standard security measures, including SSL encryption, to protect your personal information from unauthorized access, use, or disclosure. Regular malware scanning and other security measures are in place to maintain the safety of your personal information.</p>

                <h5 style={{overflowY:"hidden"}}>9. No Use of Cookies or Tracking Technologies</h5>
                <p>We do not use cookies or any tracking technologies on our website.</p>

                <h5 style={{overflowY:"hidden"}}>10. Consumer Rights under the CCPA and CPRA</h5>
                <p>The California Consumer Privacy Act (CCPA) and the California Privacy Rights Act (CPRA) provide California residents with specific rights regarding their personal information. These rights are designed to give individuals more control over the data businesses collect about them.</p>
                <h5 style={{overflowY:"hidden"}}>Rights Afforded to Consumers</h5>
                <ul>
                    <li><b>• Right to Know and Access:</b> You have the right to know about the personal data we collect about you and how it is processed and shared. This includes the right to request disclosure of the categories and specific pieces of personal information we have collected about you in the previous 12 months.</li>
                    <li><b>• Right to Deletion:</b> You have the right to request the deletion of your personal information that we have collected, subject to certain exceptions. This right enables you to ask us to delete any personal information we have collected from you, except for information necessary for specific purposes such as completing a transaction, providing a good or service requested by you, or complying with legal obligations.</li>
                    <li><b>• Right to Opt-Out of Sale of Personal Information:</b> You have the right to opt-out of the sale of your personal information to third parties. Under CCPA, the definition of “sale” is broad and includes the sharing of personal information with third parties in exchange for any valuable consideration.</li>
                    <li><b>• Right to Non-Discrimination:</b> You have the right not to receive discriminatory treatment for exercising any of your CCPA rights. Businesses cannot deny goods or services, charge different prices, or provide a different level or quality of goods or services just because you exercised your rights under the CCPA.</li>
                    <li><b>• Right to Correct Inaccurate Personal Information:</b> Under the CPRA, you have the right to request the correction of inaccurate personal information held by a business. This right ensures that you can have inaccuracies in your personal data rectified.</li>
                    <li><b>• Right to Limit Use and Disclosure of Sensitive Personal Information:</b> The CPRA provides you the right to direct businesses to limit the use of your sensitive personal information. <br /> This includes restrictions on using sensitive personal information for purposes beyond what is necessary to provide the requested services.</li>
                    <li><b>• Right to Data Portability:</b> You have the right to request the transfer of your personal data in a portable and readily usable format. This right allows you to receive your personal data in a structured, commonly used, and machine-readable format and to transfer your data to another entity.</li>
                </ul>
                <p><strong>How to Exercise Your Rights:</strong> To exercise any of the rights described above, you should submit a verifiable consumer request to us at [Your Contact Information]. Requests can typically be made through email, a form on our website, or a toll-free number.</p>
                <ul>
                  <li><b>• Verification of Requests:</b> For your protection, we will need to verify your identity before processing your request. We may ask you to provide certain information so that we can match it with the information you have previously provided us. In some cases, we may request additional documentation or proof of identity.</li>
                  <li><b>• Response Time and Format:</b> We aim to respond to a consumer request within 45 days of its receipt. If we require more time, we will inform you of the reason and extension period. Any disclosures we provide will only cover the 12-month period preceding the receipt of the verifiable consumer request. The response we provide will also explain the reasons we cannot comply with a request, if applicable.</li>
                  <li><b>• No Fee Usually Required:</b> We will not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.</li>
                </ul>

                <h5 style={{overflowY:"hidden"}}>11. Privacy Rights Under Other State Laws</h5>

                <h6 style={{overflowY:"hidden"}}>Virginia Consumer Data Protection Act (VCDPA)</h6>
                <p>Effective from January 2023</p>
                <ul>
                    <li><b>Consumer Rights:</b></li>
                    <li>•	Access: Consumers can access their personal data processed by a controller.</li>
                    <li>•	Correction: Consumers have the right to correct inaccuracies in their personal data.</li>
                    <li>•	Deletion: Consumers can request the deletion of their personal data.</li>
                    <li>•	Data Portability: Consumers have the right to obtain their personal data in a portable and, to the extent technically feasible, readily usable format.</li>
                    <li>•	Opt-Out: Consumers can opt-out of the processing of their personal data for targeted advertising, sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects.</li>
                </ul>

                <h5 style={{overflowY:"hidden"}}>Colorado Privacy Act (CPA)</h5>
                <p>Effective from July 2023</p>
                <ul>
                    <li>Consumer Rights</li>
                    <li>•	Right of Access: Consumers have the right to confirm whether a controller is processing personal data concerning them and to access such data.</li>
                    <li>•	Right of Correction: Consumers can correct inaccuracies in their personal data.</li>
                    <li>•	Right of Deletion: Consumers can delete their personal data.</li>
                    <li>•	Right to Data Portability: Consumers can obtain their data in a portable format.</li>
                    <li>•	Opt-Out Rights: Similar to VCDPA, consumers can opt-out of data processing for targeted advertising, sale of personal data, or profiling.</li>
                </ul>

                <h5 style={{overflowY:"hidden"}}>Contact Information</h5>
                <p>If you have any questions, concerns, or complaints about our Privacy Policy or the practices of our website, you can contact us at <a href="mailto:thoushallnotconfessions@outlook.com">thoushallnotconfessions@outlook.com</a></p>

            </div>
        </center>
    </>
  );
};

export default Privacy;
