// Inside PostComment component in ReactJS
import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar";
import Cookies from "js-cookie";

const Success = () => {
  const [comment, setComment] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      let newUserId="";
      newUserId = generateUniqueUserId();
      if(Cookies.get("userId")){
        newUserId=Cookies.get(userId);
      }
      else{
        Cookies.set("userId",newUserId);
      }
      setUserId(newUserId);
    }
  }, []);

  const addNewComment = async () => {
    try {
      // Post the comment to the new endpoint without checking subscribeMember
      await axios.post("https://api.thoushallnot.com/allow-comments", {
        comment,
        userId,
      });

      // Display success message
      toast.success("Comment posted successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.error("Error posting comment:", error);
      // Handle errors and display an error message
      toast.error("Error posting comment. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const clearNewComment = async () => {
    setComment("");
  };

  const generateUniqueUserId = () => {
    // Implement a function to generate a unique user identifier
    // You can use a library like uuid or implement your own logic
    // For example, using a timestamp and random number
    return `user_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  };

  return (
    <div className="container mx-auto">
      <Navbar />
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        cols="30"
        rows="10"
        placeholder="Share confession here"
      ></textarea>

      <button className="post-btn" style={{backgroundColor:"#df0000",border:"none"}} onClick={addNewComment}>
        POST
      </button>
      <button className="post-btn clear-btn" style={{backgroundColor:"#df0000",border:"none"}} onClick={clearNewComment}>
        CLEAR
      </button>

      {/* Toast container for notifications */}
      <ToastContainer />
    </div>
  );
};

export default Success;
