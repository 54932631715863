import React, { useContext } from "react";
import logo from "../assets/logo.png";
import dark from "../assets/dark.png";
import { Link } from "react-router-dom";
import { MyContext } from "./context";

const Navbar = () => {
  const { state, setState } = useContext(MyContext);

  return (
    <nav className="container mx-auto" style={{ marginBottom: "20px" }}>
      <div className="navbar" style={{ backgroundColor: state === "light" ? "#fff" : "#1E262F", marginBottom: "30px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div className="left-nav" style={{ display: "flex", alignItems: "center" }}>
          <img className="mainlogo" src={state === "light" ? logo : dark} alt="Logo" style={{ marginLeft: "30px" }} />
          </div>
            <div className="right-nav" style={{ display: "flex", alignItems: "center", fontSize: "13px" }}>
              {/* <Link to="/favorite" style={{ color: "grey", cursor: "pointer", margin: "0 4px" }}>
                Favorite
              </Link> */}
              <div className="hide-on-mobile">
                <Link to="/privacy" style={{ color: "grey", cursor: "pointer" }}>
                  Privacy Policy
                </Link>
                <Link to="/terms-of-services" style={{ color: "grey", cursor: "pointer", margin: "0 4px" }}>
                  Terms of Services
                </Link>
                <Link to="/boosting-policy" style={{ color: "grey", cursor: "pointer", margin: "0 4px" }}>
                  Boosting Policy
                </Link>
              </div>
              <i className={state === "light" ? 'fa fa-moon' : 'fa fa-sun'} style={{ color: state === "light" ? "black" : "white", marginRight: "10px", cursor: "pointer" }} onClick={() => {
                setState(state === "light" ? "dark" : "light");
              }}></i>
            </div>
        </div>
      </nav>
  );
};

export default Navbar;
