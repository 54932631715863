import React,{useContext,useEffect} from "react";
import logo from "../assets/logo.png";
import dark from "../assets/dark.png";
import { MyContext } from "./context";

const BostingPolicy = () => {
  const { state } = useContext(MyContext);
  
  useEffect(()=>{
    if(state=="light"){
      document.body.style.backgroundColor="#fff";
    }
    else{
      document.body.style.backgroundColor="#151b21";
    }
  },[state])

  return (
    <>
      <center>
        <img src={state=="light"?logo:dark} alt="ThoushallNot" style={{width:"350px"}}/>
        <h5 style={{ fontWeight: "bold", overflowY: "hidden",color:state=="light"?"black":"white" }}>Boosting Policy</h5>
        <center>
          <span style={{color:state=="light"?"black":"white"}}>Effective Date: 1/21/2024</span>
        </center>
        <div style={{ textAlign: "left", margin: "30px", overflowY: "hidden",color:state=="light"?"black":"white" }}>
          <p>
            We appreciate you using our boosting service, and we are committed to
            providing you with the best possible experience. This Boosting and
            Refund Policy outlines the terms and conditions associated with
            subscription cancellations and refunds.
          </p>
          <h5 style={{ overflowY: "hidden" }}>1. Boosting Benefits:</h5>
          <p>
            Users of Thoushallnot.com are afforded the exclusive benefit of having
            their comments prominently featured at the top, ensuring greater
            visibility.
          </p>
          <h5 style={{ overflowY: "hidden" }}>2. Boosting Pricing:</h5>
          <p>The price per one boost on Thoushallnot.com is $1.99.</p>
          <p>We accept secure payments through Stripe.</p>
          <h5 style={{ overflowY: "hidden" }}>3. No Refund Policy:</h5>
          <p>
            Please be aware that we maintain a strict no-refund policy. Once your
            boost fee is paid, it is non-refundable.
          </p>
          <p>
            We have intentionally set the fee at a nominal amount, and processing
            refunds would incur significant administrative costs.
          </p>
          <p>
            By subscribing to Thoushallnot.com, you acknowledge and agree to comply
            with this Boosting and Refund Policy. Should you have any inquiries or
            require further assistance regarding this policy, please do not
            hesitate to reach out to our dedicated customer support team at{" "}
            <b>thoushallnotconfessions@outlook.com</b>
          </p>
        </div>
      </center>
    </>
  );
};

export default BostingPolicy;
