import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import toast,{Toaster} from 'react-hot-toast';
import { MyContext } from "./context";

const categories = [
  "choose confession category",
  "Personal Growth",
  "Relationships",
  "Workplace",
  "School/College",
  "Social Issues",
  "Regrets",
  "Secrets",
  "Achievements",
  "Fears",
  "Dreams and Aspirations",
  "Feedback",
  "Question",
  "Other"
];

const PostComment = () => {
  const [comment, setComment] = useState("");
  const [category, setCategory] = useState(categories[0]); 
  const [hasCommented, setHasCommented] = useState(false);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const { state } = useContext(MyContext);

  useEffect(()=>{
    if(state=="light"){
      document.body.style.backgroundColor="#fff";
    }
    else{
      document.body.style.backgroundColor="#151b21";
    }
  },[state])

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      let newUserId = "";
      newUserId = generateUniqueUserId();
      if (localStorage.getItem("userId")) {
        newUserId = localStorage.getItem("userId");
      } else {
        localStorage.setItem("userId", newUserId);
      }
      setUserId(newUserId);
    }
  }, []);

  const addNewComment = async () => {
    try {
      await axios.post("https://api.thoushallnot.com/post-comments", {
        comment,
        category,
        userId,
      });

      setComment("");
      setCategory(categories[0]); 

      toast.success("Comment posted successfully!");
    } catch (error) {
      console.error("Error posting comment:", error);
      toast.error("You can only comment once in free. You have to pay a yearly subscription to leave unlimited comments");
    }
  };

  const clearNewComment = async () => {
    setComment("");
    setCategory(categories[0]); 
  };

  const handleSubscribeClick = () => {
    navigate("/subscription");
  };

  const generateUniqueUserId = () => {
    return `user_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  };

  return (
    <div className="container mx-auto">
      <Navbar />

      <div className="mb-3">
        <select
          id="category"
          className="form-select"
          value={category}
          style={{backgroundColor:state=="light"?"#fff":"#1e262f"}}
          onChange={(e) => setCategory(e.target.value)}
        >
          {categories.map((cat, index) => (
            <option key={index} value={cat}>
              {cat}
            </option>
          ))}
        </select>
      </div>

      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        cols="30"
        rows="10"
        placeholder="let it all out here ☕️"
        style={{border:"1px solid #bdbdbd",borderRadius:"5px",backgroundColor:state=="light"?"#fff":"#1e262f", color:state=="light"?"#000":"#fff"}}
      ></textarea>

      <center>
        <button className="post-btn" style={{ backgroundColor: "#df0000", border: "none" }} onClick={addNewComment}>
          POST
        </button>
        <button className="post-btn clear-btn" style={{ backgroundColor: "#df0000", border: "none" }} onClick={clearNewComment}>
          CLEAR
        </button>
      </center>

      {hasCommented && (
        <div className="subscription-popup">

        </div>
      )}

      <Toaster />
    </div>
  );
};

export default PostComment;
