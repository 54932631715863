import React, { useState, useEffect } from "react";
import { MyContext } from "./context";

export const MyProvider = ({ children }) => {
  const [state, setState] = useState(() => {
    const savedState = localStorage.getItem("theme");
    return savedState !== null ? savedState : "light"; 
  });

  useEffect(() => {
    setState(state);
    localStorage.setItem("theme", state);
  }, [state]);

  return (
    <MyContext.Provider value={{ state, setState }}>
      {children}
    </MyContext.Provider>
  );
};
