import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("http://api.thoushallnot.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
        navigate("/new-post"); // Successful login
      } else {
        console.error(data.error); // Invalid credentials
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (

    <div>
      <div
        className="d-flex justify-content-center align-items-center text-center vh-100"
        style={{
          backgroundColor: "white",
          color:"#a1151c"
        }}
      >
        <div
          className="bg-white p-3 rounded"
          style={{ width: "40%", border: "2px solid black" }}
        >
          <h3 className="mb-3 form-log">LOGIN</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 text-start">
              <label htmlFor="exampleInputEmail1" className="form-label">
                <strong>Email Id</strong>
              </label>
              <input
                type="email"
                placeholder="Enter Email"
                className="form-control"
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3 text-start">
              <label htmlFor="exampleInputPassword1" className="form-label">
                <strong>Password</strong>
              </label>
              <input
                type="password"
                placeholder="Enter Password"
                className="form-control"
                id="exampleInputPassword1"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
             type="submit"
              className="btn btn-primary"
              style={{
          backgroundColor: "black",
          color:"#fff",
          border:"black"
           }}>
              Login
            </button>
          </form>

          <p className="container my-2">Already have an account ?</p>
          <Link to="/register" className="btn btn-danger">
            Register
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
